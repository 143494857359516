import React from "react";

import { HeroWelcome } from "components/views/HeroWelcome";
import classNames from "classnames";

const Tableview = () => {
  const instructionText =
    "This feature mock has not been ported yet, but will be available here once it is. " +
    "This placholder shows one use of a Hero Welcome view.";
  return (
    <HeroWelcome
      iconName="accountHardHat"
      title="We're Working On It"
      instructions={instructionText}
      className={classNames("flex-fill")}
    />
  );
};

export default Tableview;
